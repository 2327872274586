<template>
  <div>
    <section>
      <Hero>
        <div class="container">
          <HeadText :titletext="'Passwort ändern'" :subtext="''"></HeadText>
        </div>
        <div v-if="!hasToken" class="container bg-light">
          <div class="alert alert-warning" role="alert">
            <p>Bitte melden Sie sich an, um auf diese Seite zuzugreifen.</p>
          </div>
        </div>
        <div v-else-if="error" class="container bg-light">
          <div class="alert alert-danger" role="alert">
            <p>Es ist ein Fehler aufgetreten.</p>
            <p class="fs-7">{{ error }}</p>
          </div>
        </div>
        <div v-else>
          <div class="container bg-light">
            <h3 class="text-left pt-3">Auf dieser Seite können Sie Ihr persönliches Passwort ändern.</h3>

            <div class="row pt-3 pl-3 pr-3" v-if="validationResults && validationResults.length > 0">
              <div class="text-left w-100">
                <b-alert show variant="danger" v-for="validationResult in validationResults" :key="validationResult">
                  {{validationResult}}
                </b-alert>
              </div>
            </div>

            <div class="row pt-3 pl-3 pr-3" v-if="userMustChangePassword">
              <div class="text-left w-100">
                <b-alert show variant="warning">
                  Ihr Passwort ist nur einmal gültig. Bitte setzen Sie jetzt ein neues Passwort.
                </b-alert>
              </div>
            </div>

            <div class="row pt-3 pl-3 pr-3" v-if="submitSuccess">
              <div class="text-left w-100">
                <b-alert show variant="success" dismissible>Ihr neues Passwort wurde erfolgreich gespeichert</b-alert>
              </div>
            </div>
            
            <div class="row justify-content-around pt-3">
              <div class="col-sm-6 text-left">

                <b-form @submit.stop.prevent="onSubmit">
                  <b-form-group
                      id="input-group-1"
                      label="Altes Passwort:"
                      label-for="oldPasswordInputField"
                  >
                    <b-form-input
                        id="oldPasswordInputField"
                        v-model.trim="$v.form.oldPassword.$model"
                        type="password"
                        placeholder="Altes Passwort"
                        :state="validateState('oldPassword')"
                        aria-describedby="oldPasswordInputField-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="oldPasswordInputField-live-feedback">
                      Bitte geben Sie Ihr altes Passwort an.
                    </b-form-invalid-feedback>
                  </b-form-group>
                  
                  <b-form-group id="input-group-2" >
                    <label id="newPasswordInputField-label" for="newPasswordInputField">
                      <span>Neues Passwort: <i id="newPasswordInputField-label-hint" class="fas fa-question-circle"></i></span>
                    </label>
                    <b-form-input
                        id="newPasswordInputField"
                        type="password"
                        v-model.trim="$v.form.newPassword.$model"
                        placeholder="Neues Passwort"
                        @keyup="preCheckPassword"
                        :state="validateState('newPassword')"
                        aria-describedby="newPasswordInputField-live-feedback"
                    ></b-form-input>
                    <b-form-invalid-feedback id="newPasswordInputField-live-feedback">
                      <span v-if="!$v.form.newPassword.$model">Bitte geben Sie Ihr neues Passwort an.</span>
                      <span v-else-if="!$v.form.newPassword.illegalCharactersOk === true">Das Passwort enthält unerlaubte Sonderzeichen.</span>
                      <span v-else-if="$v.form.newPassword.$model === $v.form.oldPassword.$model">Bitte geben Sie ein anderes Passwort ein als Ihr bisheriges Passwort.</span>
                      <span v-else-if="!$v.form.guidelinesFulfilled === true">Das neue Passwort entspricht nicht den Mindestanforderungen.</span>
                      <span v-else-if="!$v.form.sufficient === true">Das neue Passwort ist zu schwach.</span>
                      <span v-else>Das neue Passwort ist ungenügend.</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
  
                  <b-form-group id="input-group-3" 
                                label="Passwort wiederholen:" 
                                label-for="newPasswordRepeatInputField"
                  >
                    <b-form-input
                        id="newPasswordRepeatInputField"
                        type="password"
                        v-model.trim="$v.form.newPasswordRepeat.$model"
                        placeholder="Wiederholung"
                        :state="validateState('newPasswordRepeat')"
                        aria-describedby="newPasswordRepeatInputField-live-feedback"
                        :disabled="passwordRepeatDisabled"
                    ></b-form-input>
                    <b-form-invalid-feedback id="newPasswordRepeatInputField-live-feedback">
                      <span v-if="!$v.form.newPasswordRepeat.$model">Bitte geben Sie Ihr neues Passwort wiederholt ein.</span>
                      <span v-else>Neues Passwort und Passwortwiederholung stimmen nicht überein.</span>
                    </b-form-invalid-feedback>
                  </b-form-group>
                  
                  <b-button 
                      id="password-form-submit"
                      type="submit" 
                      variant="default"
                      >
                    <span class="pr-2" v-if="submitInProgress"><b-spinner small></b-spinner></span><span>Speichern</span>
                  </b-button>
                  
                  <b-tooltip v-if="hintHtml" target="newPasswordInputField-label-hint" triggers="hover" placement="topright">
                    <span v-html="hintHtml"></span>
                  </b-tooltip>
                </b-form>
              </div>
            
              <div class="col-sm-6 text-left">
                <p>
                  Passwortstärke:
                  <b-progress :value="this.passwordStrengthTest.bar_fill_percant" :max="100" :variant="this.passwordStrengthTest.variant"></b-progress>
                </p>
                <p>
                  <span class="d-inline-block" tabindex="0">
                    <span>Mindestanforderungen erfüllt: <i id="min-requirements-wrapper" class="fas fa-question-circle"></i></span>
                  </span>
                  <b-tooltip target="min-requirements-wrapper" triggers="hover">
                    Die Passwortstruktur wird interaktiv geprüft. Die Wiederverwendung wird erst beim Speichern geprüft.
                  </b-tooltip>
                  <span v-if="form.guidelinesFulfilled" style="color:green" class="pl-3"><i class="fas fa-check"></i></span>
                  <span v-else style="color:red" class="pl-3"><i class="fas fa-times"></i></span>
                </p>
              </div>
            </div>
            <PasswordGuidelinesInfo></PasswordGuidelinesInfo>
          </div>
        </div>
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import axios from "axios";
import testPassword from "@/mixins/testPassword";
import testForIllegalCharacters from "@/mixins/testForIllegalCharacters";
import PasswordGuidelinesInfo from "../components/password/PasswordGuidelinesInfo";
import { validationMixin } from 'vuelidate'
import {required, sameAs, not} from "vuelidate/lib/validators";

const strengthOk = (value, vm) => {
  return vm.sufficient === true
}
const minRequirementsOk = (value, vm) => {
  return vm.guidelinesFulfilled === true
}
const illegalCharactersOk = (value) => {
  return testForIllegalCharacters(value);
}

export default {
  name: "ChangePassword",
  components: {Hero, HeadText, PasswordGuidelinesInfo},
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
      ],
    }
  },
  data() {
    return {
      error: null,
      hintHtml: null,
      passwordStrengthTest: {
        variant: "danger",
        bar_fill_percant: 0,
        pwd_strength_res: "",
        sufficient: false,
      },
      form: {
        oldPassword: null,
        newPassword: null,
        newPasswordRepeat: null,
        // "pseudo" fields needed for validation
        sufficient: undefined,
        guidelinesFulfilled: undefined,
        noIllegalCharacters: true,
      },
      validationResults: [],
      submitInProgress: false,
      submitSuccess: false,
  }},
  validations() {
    return {
      form: {
        oldPassword: {
          required,
        },
        newPassword: {
          required,
          strengthOk,
          minRequirementsOk,
          illegalCharactersOk,
          notSameAsPassword: not(sameAs('oldPassword')),
        },
        newPasswordRepeat: {
          required,
          sameAsPassword: sameAs('newPassword'),
        },
      },
  }},
  computed: {
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    passwordRepeatDisabled() {
      var passwordGiven = this.form.newPassword != null && 
          this.form.newPassword !== '' && 
          this.form.sufficient && 
          this.form.guidelinesFulfilled &&
          this.form.noIllegalCharacters
      return !passwordGiven;
    },
    userMustChangePassword() {
      return this.$store.getters['user/isChangePasswordEnforced'];
    },
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    validateState(fieldName) {
      const { $dirty, $error } = this.$v.form[fieldName];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      if ( this.submitInProgress ) {
        return;
      }
      this.submitInProgress = true;
      this.submitSuccess = false;
      this.$v.$touch();
      if (this.$v.form.$anyError) {
        this.submitInProgress = false;
        return;
      }
      this.submitNewPassword();
    },
    mapViolation( violation ) {
      if ( 'NotRecentPasswordPolicy' === violation || 'NotSamePasswordPolicy' === violation ) {
        return 'Keine Wiederholung von kürzlich verwendeten Passwörtern'
      }
      else if ( 'PasswordMismatchException' === violation ) {
        return 'Bitte überprüfen Sie die Eingabe des alten Passworts, und dass das neue Passwort und die Wiederholung gleich sind'  
      }
      
      var message = 'Anzahl ';
      if ( 'ContainsDigitsPolicy' === violation ) {
        message += 'Ziffer';
      } else if ( 'ContainsLowercasePolicy' === violation ) {
        message += 'Kleinbuchstaben';
      } else if ( 'ContainsUppercasePolicy' === violation ) {
        message += 'Großbuchstaben';
      } else if ( 'ContainsSpecialCharactersPolicy' === violation ) {
        message +='Sonderzeichen';
      } else if ( 'MinimumLengthPolicy' === violation ) {
        message += 'Zeichen';
      } else {
        message += '[' + violation + ']';
      }
      
      return message + ' nicht genügend'
    },
    getHint() {
      axios.get( this.$store.getters.getAllEnvis.API_PASSWORD_HINT.replace('{subsite}', this.$store.getters["getSubsite"]) )
          .then( response => {
            this.hintHtml = response.data;
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
          })
    },
    preCheckPassword() {
      this.passwordStrengthTest = testPassword( this.form.newPassword );
      this.form.noIllegalCharacters = testForIllegalCharacters( this.form.newPassword );
      this.form.sufficient = this.passwordStrengthTest.sufficient ? true : undefined;
      var config = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      };
      var subsite = null;
      if(this.$store.getters['getSubsite']) {
        subsite = this.$store.getters['getSubsite'];
      } else {
        subsite = window.location.pathname?.split('/')?.[1];
      }
      const payload = JSON.stringify( {new_password: this.form.newPassword} );
      axios.post( this.$store.getters.getAllEnvis.API_PASSWORD_CHECK.replace('{subsite}', subsite),
          payload, config )
          .then( () => {
            this.form.guidelinesFulfilled = true;
          })
          .catch(error => {
            this.form.guidelinesFulfilled = undefined;
            if ( error.response.status !== 400 ) {
              console.log(error);
              this.error = error;
            }
          })
    },
    submitNewPassword() {
      const token = this.$store.getters["auth/getToken"];
      var config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      }
      var subsite = null;
      if(this.$store.getters['getSubsite']) {
        subsite = this.$store.getters['getSubsite'];
      } else {
        subsite = window.location.pathname?.split('/')?.[1];
      }
      const payload = JSON.stringify( {old_password: this.form.oldPassword, new_password: this.form.newPassword} );
      axios.post( this.$store.getters.getAllEnvis.API_PASSWORD_CHANGE.replace('{subsite}', subsite),
          payload, config )
          .then( () => {
            this.submitSuccess = true;
            this.$store.dispatch('user/resetChangePasswordEnforced')
          })
          .catch(error => {
            this.validationResults = [];
            if ( error.response.status === 400 ) {
              var violations = error.response.data.violations;
              for (var i = 0; i < violations.length; i++){
                var violation = violations[i];
                this.validationResults[i] = this.mapViolation( violation['policy'] );
              }
            }
            else if ( error.response.status === 401 ) {
              this.validationResults[0] = 'Ihre Sitzung ist abgelaufen, bitte melden Sie sich neu an'
            }
            else {
              console.log( error );
              this.error = error;
            }
          })
          .finally( () => {
            this.submitInProgress = false; 
          })
    }
  },
  created() {
    this.getHint();
  },
  mixins: [validationMixin],
}
</script>

<style scoped>

</style>